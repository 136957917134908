import React from "react";
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-4">Введение</h1>
      <p>
       «Vita Balance» (далее – «Компания») является собственником базы, содержащей персональные данные, которые
        Компания собирает во время посещения Пользователями Магазинов Vita Balance,
        ПВЗ, СЦ на территории Казахстана или использования Интернет-магазина/Сайта,
        Мобильного приложения. В настоящей Политике конфиденциальности Компания
        собрала информацию о том, какие персональные данные Компания собирает, как
        и для каких целей использует персональные данные, что является основанием
        использования персональных данных, кому передаются персональные данные, а
        также каким образом Пользователи могут реализовывать свои права.
      </p>
      <p>
        Настоящая Политика конфиденциальности является неотъемлемой частью договора
        розничной купли-продажи (Договора).
      </p>
      

      <ol>
      <li className="font-bold text-lg"><a href="#definitions">1.ОПРЕДЕЛЕНИЯ</a></li>
        <li className="font-bold text-lg"><a href="#information">2.ИНФОРМАЦИЯ, КОТОРУЮ VitaBalance СОБИРАЕТ И ОБРАБАТЫВАЕТ</a></li>
        <li className="font-bold text-lg uppercase"><a href="#collection_purposes">3.цели сбора и обработки персональных данных</a></li>
        <li className="font-bold text-lg uppercase"><a href="#legal_basis">4.правовые основания обработки персональных данных</a></li>
        <li className="font-bold text-lg uppercase"><a href="#data_protection">5.защита персональных данных</a></li>
        <li className="font-bold text-lg uppercase"><a href="#rights">6.права</a></li>
        <li className="font-bold text-lg uppercase"><a href="#data_storage">7.хранение персональных данных</a></li>
        
      </ol>
            <br/>
            <Link to="/" className="font-bold text-lg">
      <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
      Вернуться назад
      </button>
      
      </Link>
            <br/>
                               
       <div className="container mx-auto py-8" id="definitions">
      <h1 className="text-3xl font-bold mb-4" >ОПРЕДЕЛЕНИЯ</h1>
      <ul>
        
        <li>
          <strong>Интернет-магазин/Сайт</strong> – информационная система, размещенная в сети интернет с доменным именем vita-balance.kz, предназначенная для реализации Товаров Покупателям.
        </li>
        <li>
          <strong>Мобильное приложение</strong> – специально разработанное программное обеспечение для работы на смартфонах, планшетах и других мобильных устройствах, разработанное для конкретной платформы (iOS, Android, WindowsPhone и т. д.), размещенное на сервисах GooglePlayStore, AppStore для скачивания и установки Покупателями, имеющее название «VitaBalance», предназначенное для реализации Товаров/Услуг Покупателям.
        </li>
        <li>
          <strong>Платформа</strong> – все и любые каналы связи, через которые осуществляется прием и/или передача персональных данных Пользователей, включая, но не ограничиваясь:
          <ul>
            <li>Интернет-магазин/Сайт;</li>
            <li>Мобильное приложение;</li>
            <li>Магазины, ПВЗ, СЦ;</li>
            <li>Контакт-центр;</li>
            <li>Страницы в социальных сетях включая, но не ограничиваясь Facebook, Instagram, Telegram;</li>
            <li>Все любые иные каналы коммуникации, осуществляющие деятельность под товарными знаками «VitaBalance.kz».</li>
          </ul>
        </li>
        <li>
          <strong>Пользователи</strong> – физические лица, взаимодействующие с VitaBalance посредством Платформы от своего имени, либо физические лица, выступающее от имени и в интересах представляемого ими юридического лица, а также индивидуальные предприниматели, включая зарегистрированных пользователей, и пользователей, не имеющих учетной записи, неавторизированных пользователей.
        </li>
        <li>
          <strong>Cookies</strong> - небольшие текстовые файлы, в которые браузер записывает данные с посещенных Покупателями сайтов. Cookies исполняют целый ряд функций, например, позволяют Пользователю эффективно перемещаться между страницами, запоминая его предпочтения, и, таким образом, улучшает опыт взаимодействия с Интернет-магазином/Сайтом. Они также могут способствовать тому, что реклама, которую видит Пользователь онлайн, соответствует его интересам.
        </li>
      </ul>
      <p>
        Все определения, используемые в настоящей Политике конфиденциальности, применяются и трактуются согласно определений, указанных в Договоре и/или всех иных приложениях, политиках, правилах, дополнениях, заявлениях и согласиях, являющихся неотъемлемой частью Договора и/или согласно законодательству Республики Казахстан.
      </p>
    </div>
    // 2
    <div className="container mx-auto py-8" id="information">
      <h1 className="text-3xl font-bold mb-4">ИНФОРМАЦИЯ, КОТОРУЮ VitaBalance СОБИРАЕТ И ОБРАБАТЫВАЕТ</h1>
      <p>
        VitaBalance может запросить у Пользователей личную информацию, необходимую для предоставления запрашиваемых Пользователями Услуг, продажи/возврата Товаров, получения консультаций через Платформу, для участия в Клубе привилегий, при обращении за получением кредита. Пользователи также передают VitaBalance свои персональные данные при регистрации/взаимодействии с VitaBalance на Платформе. Персональные данные, которые Пользователи передают VitaBalance для исполнения договора розничной купли-продажи, перечислены в публичной Оферте, размещенной в Мобильном приложении и Интернет-магазине/Сайте. <a href="https://vita-balance.kz/">https://vita-balance.kz/</a>
      </p>
      <h2>Персональные данные, которые VitaBalance собирает и обрабатывает</h2>
      <p>
        VitaBalance собирает и обрабатывает следующие персональные данные:
      </p>
      <h3>Персональные данные, которые Пользователи передают VitaBalance</h3>
      <p>
        Персональные данные, которые Пользователи передают нам:
      </p>
      <ul>
        <li>
          <strong>Идентификационные данные</strong> – это данные, которые позволяют установить личность Пользователя, включая, но не ограничиваясь следующими видами данных: биометрические данные, фамилия, имя, пол, дата рождения, электронный адрес, адрес доставки, номер сотового телефона, индивидуальный идентификационный номер, записи телефонных разговоров, видеозаписи камер наблюдения в Магазине и ПВЗ, логин для входа в учетную запись;
        </li>
        <li>
          <strong>Информация о транзакциях</strong> – информация о покупках, заказах, возвратах, обращениях Пользователя в Контакт-центр, СЦ, совершенных посредством Платформы;
        </li>
        <li>
          <strong>Маркетинговые и коммуникационные данные</strong>, включая настройки подписки на рекламные рассылки;
        </li>
        <li>
          <strong>Отзывы</strong>, которые Пользователи оставляют о Товарах/Услугах на Платформе, а также другие комментарии и рекомендации.
        </li>
      </ul>
      <h3>Данные, которые VitaBalance собирает автоматически</h3>
      <p>
        VitaBalance также собирает сведения о взаимодействии Пользователей с Платформой с помощью файлов cookie.
      </p>
      <h3>Данные, которые VitaBalance получает от Партнеров</h3>
      <p>
        VitaBalance также может получать другие данные Пользователей от Партнеров, других лиц.
      </p>
      <p>
        С целью оптимизации и персонификации работы Платформы персональные данные или данные, собранные автоматически, полученные от Пользователей и/или Партнеров и/или других лиц, могут объединяться в один пул данных или накапливаться в независимых друг от друга базах данных и обрабатываться отдельно.
      </p>
      <p>
        Все действия VitaBalance осуществляются с соблюдением прав Пользователей, предусмотренных законодательством Республики Казахстан.
      </p>
      <p>
        Пользователи путем письменного обращения могут отказаться от использования ряда персональных данных, и это может означать, что Пользователь не сможет воспользоваться каким-то функционалом Платформы, и/или получать полную информацию, доступ к тем или иным услугам и/или информации.
      </p>
    </div>
            //  3
     <div className="container mx-auto py-8" id="collection_purposes">
      <h1 className="text-3xl font-bold mb-4">Цели сбора и обработки персональных данных</h1>
      <p>
        VitaBalance может осуществлять сбор и обработку персональных данных в следующих целях:
      </p>
      <ol>
        <li>
          <strong>Продажа Товаров, оказание Услуг</strong> – для предоставления информации о Товарах/Услугах, предоставления гарантийного обслуживания, обработки Заказов, идентификации Пользователей на Платформе, формировании профилей на Платформе для новых Пользователей, а также для клиентской поддержки;
        </li>
        <li>
          <strong>Рассмотрение претензий и разрешение споров</strong> – для получения полной и развернутой информации о деталях претензий и/или споров, необходимой для их разрешения.
        </li>
        <li>
          <strong>Администрирование Платформы</strong> – для администрирования профилей Пользователей, поиска и устранения неполадок, ведения отчетности и размещения данных, выполнения иных административных действий с целью управления Платформой.
        </li>
        <li>
          <strong>Улучшение качества Товаров/Услуг, развитие бизнеса</strong> – для повышения качества, включая настройку Платформы с учетом предпочтений и интересов Пользователей, предложение новых Товаров/Услуг. VitaBalance использует персональные данные для проведения исследований, анализа, опросов, которые помогут проанализировать предпочтения Пользователей в покупках, определить Товары/Услуги, максимально отвечающие требованиям Пользователей, и оценить эффективность рекламы, для контроля удовлетворенности Пользователей.
        </li>
        <li>
          <strong>Маркетинг</strong> – для информирования Пользователей о Товарах Услугах, направления информации о скидках и акциях, специальных предложениях и всех любых других материалов, проведения акций и/или розыгрышей.
        </li>
        <li>
          <strong>Персонализированная реклама</strong> – для определения интересов Пользователей и/или демонстрации наиболее релевантной рекламы, предложений.
        </li>
        <li>
          
        </li>
        <li>
          <strong>Исполнение законных обязанностей</strong>: у VitaBalance есть обязанности по закону и ряд персональных данных VitaBalance обрабатывает для того, чтобы исполнить обязательства по закону.
        </li>
        <li>
          <strong>Статистические данные</strong>.
        </li>
      </ol>
    </div>
        // 4    
     <div className="container mx-auto py-8" id="legal_basis">
      <h1 className="text-3xl font-bold mb-4">ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
      <p>
        Момент начала использования Платформы Пользователем означает согласие с настоящей Политикой конфиденциальности и ее принятие в полном объеме без дополнений и исключений, и означает его полное безусловное согласие на сбор, систематизацию, накопление, хранение (уточнение, обновление, изменение), обработку, использование, передачу третьим лицам, обезличивание, блокирование, уничтожение и трансграничную передачу персональных данных, зафиксированных на электронных, бумажных, и (или) иных носителях, имеющихся в любых источниках, в том числе общедоступных, которые были, будут или могут быть известны Компании и/или Партнерам или получены ими от Пользователя и/или любыми способами, не противоречащими законодательству Республики Казахстан в процессе осуществления их деятельности и/или при использовании любых источников, в том числе общедоступных.
      </p>
      <p>
        Согласие действует в течение всего срока действия Договора, и в течение 5 (пять) лет после его окончания.
      </p>
      <p>
        В случае несогласия с условиями Политики конфиденциальности, Пользователь должен прекратить использование Платформы незамедлительно.
      </p>
      <p>
        Настоящая Политика конфиденциальности применяется только к Платформе, и VitaBalance не контролирует и не несет ответственность за сайты, приложения третьих лиц, на которые Пользователь может перейти по доступным ссылкам.
      </p>
      <p>
        VitaBalance не проверяет достоверность данных, предоставляемых Пользователем Сайта, Приложения. Всю ответственность за достоверность Пользователь несет самостоятельно в порядке и на условиях, установленных требованиями норм законодательства Республики Казахстан.
      </p>
    </div>
    //  5
    <div className="container mx-auto py-8" id="data_protection">
      <h1 className="text-3xl font-bold mb-4">ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
      <p>
        VitaBalance предпринимает все необходимые организационные, технические и правовые меры для защиты персональных данных Пользователей в соответствии с требованиями законодательства Республики Казахстан.
      </p>
      <h2 className="text-xl font-bold mt-4">Обязанности Пользователя</h2>
      <p>
        Предоставляя VitaBalance доступ к персональным данным, Пользователь гарантирует их достоверность, а также обязуется уведомлять VitaBalance об изменениях персональных данных. VitaBalance рекомендует Пользователям соблюдать базовые меры защиты персональных устройств, через которые осуществляется доступ к Платформе, паролей и личной информации, в том числе следующие меры безопасности:
      </p>
      <ul className="list-disc ml-8">
        
        <li>быть осторожным, отвечая на электронную почту, телефонный звонок, сообщение в мессенджер или письмо от любого, кто запрашивает пароль, данные удостоверения личности или банковской карты и другие данные. Не передавать такие данные, созвониться с Контакт-центром для выяснения ситуации относительно правомерности полученного запроса о предоставлении данных;</li>
        <li>вводить пароль и номер кредитной карты только через безопасное соединение в Интернет-магазине (значок «https/http» в адресной строке браузера означает, что соединение безопасно).</li>
      </ul>
      <p>
        Всю ответственность за негативные последствия, вызванные ненадлежащей осмотрительностью Пользователя, связанные с получением доступа неуполномоченных лиц к персональным данным Пользователя, Пользователь несет в полном объеме самостоятельно.
      </p>
    </div>
    <div className="container mx-auto py-8" id="rights">
      <h1 className="text-3xl font-bold mb-4">ПРАВА ПОЛЬЗОВАТЕЛЕЙ (продолжение)</h1>
      <ol className="list-decimal ml-8">
        <li>Пользователь обладает правом знать о наличии у VitaBalance персональных данных, которые хранятся и обрабатываются.</li>
        <li>Пользователь имеет право на получение информации о том, как используются его персональные данные. Полная информация о целях использования, способах сбора и обработки персональных данных, а также сроках обработки и хранения персональных данных содержится в настоящей Политике конфиденциальности, а также в Договоре и/или его неотъемлемых частях.</li>
        <li>Пользователь вправе запрашивать изменения или дополнения персональных данных, а также исправления неточной информации о себе. В определенных случаях VitaBalance может запросить у Пользователя подтверждающие документы, включая документ, удостоверяющий личность.</li>
        <li>Если Пользователь зарегистрирован на Платформе, он может изменять или обновлять некоторые персональные данные в соответствующем разделе своего аккаунта на Платформе. Обновить или изменить данные можно путем обращения в Контакт-центр или в Магазине.</li>
        <li>Пользователь вправе требовать блокировки (временного прекращения сбора и обработки) персональных данных в случае нарушения условий сбора и обработки персональных данных VitaBalance.</li>
        <li>Пользователь вправе требовать уничтожения персональных данных, когда сбор и обработка данных осуществляются VitaBalance на основе согласия. При этом VitaBalance оставляет за собой право обработки персональных данных, которые собраны на основе договора, а также по другим основаниям, указанным в настоящей политике и официальном уведомлении.</li>
        <li>Пользователь вправе в любой момент отозвать свое согласие на сбор и обработку своих персональных данных.</li>
      </ol>
      <p>
        Данные права могут быть реализованы путем отправки предварительного уведомления об этом VitaBalance по юридическому адресу: Республика Казахстан, г. Алматы, Алмалинский район, ул. Курмангазы, дом 178А, БИН 121140008045 в срок не менее чем за 30 (тридцать) дней до вступления отзыва в силу, исчисляемых с даты получения VitaBalance соответствующего уведомления. Права не могут быть реализованы в случаях, если это противоречит законодательству Республики Казахстан, а также при наличии неисполненных Пользователем обязательств перед VitaBalance.
      </p>
    </div>
     <div className="container mx-auto py-8" id="data_storage">
      <h1 className="text-3xl font-bold mb-4">ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
      <p>
        VitaBalance хранит персональные данные Пользователей с целью осуществления обзвона для информирования о продуктах и услугах, а также для продажи продуктов и услуг, соответствующих интересам Пользователей.
      </p>
      <p>
        Обработка и хранение персональных данных осуществляются в соответствии с требованиями законодательства Республики Казахстан и регулирующими документами ВитаБаланс.
      </p>
      <p>
        VitaBalance предпринимает все необходимые организационные, технические и правовые меры для обеспечения безопасности и конфиденциальности персональных данных Пользователей.
      </p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
